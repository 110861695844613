import ArticleList from '../articles/ArticleList';

export const getArticle = (articleRef) => {
  
  var promise = new Promise( (resolve, reject) => {
    let article = ArticleList.find((article) => {
      return article.ref === articleRef;
    });
 
    if (article === null) {
      reject(Error("Promise rejected"));
    }
    else {
      resolve(article);
    }
  });

  return promise;
}

export const getNthMostRecentArticle = (articleIndex) => {
  
  var promise = new Promise( (resolve, reject) => {
 
    if (ArticleList[articleIndex] < 0) {
      reject(Error("Promise rejected"));
    }
    else {
      resolve(ArticleList[articleIndex]);
    }
  });

  return promise;
}

export const getAllArticles = () => {
  
  var promise = new Promise( (resolve, reject) => {
 
    if (ArticleList && ArticleList.length < 1) {
      reject(Error("Promise rejected"));
    }
    else {
      resolve(ArticleList);
    }
  });

  return promise;
}