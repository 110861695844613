import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@material-ui/core";
import { getAllArticles } from "../services/ArticleService";
import { Card, CardMedia, CardContent, CardActions, Button, Typography } from '@mui/material';
import variables from '../variables.scss';
import oddsImage from './images/bets.png';

export default function Gamble() {
  const [articleList, setArticleList] = useState([]);
  let { article_ref } = useParams();

  useEffect(() => {
    getAllArticles().then((res) => setArticleList(res));
  }, [article_ref]);

  const handleClick = value => () => {
    window.location.href='/article/' + value;
  }

  const displayArticleList = () => {
    return articleList.map((article) => {
      return (
        <Card sx={{ width: 350, display: "flex", flexDirection: "column", justifyContent: "flex-start", backgroundColor: variables.primaryFont }} key={article.ref + ".card"}>
          <CardMedia
            component="img"
            height="200"
            image={article.image}
            alt="Race Cover Image"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{color: variables.primaryPurple}}> 
              {article.title}
            </Typography>
            <Typography variant="body1" color={variables.primaryPurple} sx={{ paddingBottom: "11px" }} >
              {article.date}
            </Typography>
            <Typography variant="body2" color={variables.primaryPurple}>
              {article.firstLine}
            </Typography>
          </CardContent>
          <CardActions sx={{ marginTop: "auto" }}>
            <Button sx={{color: variables.primaryGreen}} size="small" onClick={handleClick(article.ref)} key={article.ref + ".button"}>Read Full Article</Button>
          </CardActions>
        </Card>
      );
    });
  };

  return (
    <Container style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "stretch", gap: "11px", marginTop: "11px", marginBottom: "11px" }}>
      <Card sx={{ width: 350, display: "flex", flexDirection: "column", justifyContent: "flex-start", backgroundColor: variables.primaryFont }}>
        <CardMedia
          component="img"
          height="200"
          image={oddsImage}
          alt="Odds Image"
        />  
        <CardContent sx={{color: variables.primaryPurple}}>
          <Typography gutterBottom variant="h5" component="div" sx={{color: variables.primaryPurple}}> 
            Ideal Lineup for Atlanta
          </Typography>
          <Typography variant="body2" color={variables.primaryPurple}>
            <div style={{paddingLeft: "11px"}}>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#24, William Byron</div>
                <div style={{minWidth: "75px"}}>92.1pts</div>
                <div style={{flexGrow: "1"}}>$10.5k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#7, Corey Lajoie</div>
                <div style={{minWidth: "75px"}}>82.5pts</div>
                <div style={{flexGrow: "1"}}>$3k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#1, Ross Chastain</div>
                <div style={{minWidth: "75px"}}>79.2pts</div>
                <div style={{flexGrow: "1"}}>$7k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#99, Daniel Suarez</div>
                <div style={{minWidth: "75px"}}>75.3pts</div>
                <div style={{flexGrow: "1"}}>$4.5k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#19, Martin Truex Jr.</div>
                <div style={{minWidth: "75px"}}>72pts</div>
                <div style={{flexGrow: "1"}}>$10k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px", fontWeight: "bold"}}>
                <div style={{minWidth: "150px"}}>Totals</div>
                <div style={{minWidth: "75px"}}>404.1pts</div>
                <div style={{flexGrow: "1"}}>$35k</div>
              </div>
            </div>
          </Typography>
        </CardContent>
        <CardActions sx={{ marginTop: "auto" }}>
          <Button sx={{color: variables.primaryGreen}} size="small" href="https://sportsbook.fanduel.com/motorsport">More Motorsports Odds</Button>
        </CardActions>
      </Card>

      {displayArticleList()}
    </Container>
  );
}