import './App.scss';
import { BrowserRouter as Router, Routes , Route } from "react-router-dom";
import Header from './components/Header';
import Home from './components/Home';
import Race from './components/Race';
import Gamble from './components/Gamble';
import Buschington from './components/Buschington';
import Article from './components/Article';

function App() {
  return (
    <div className="App">
      <Router>
        <Header/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/iracing" element={<Race/>} />
          <Route path="/gambling" element={<Gamble/>} />
          <Route path="/buschington" element={<Buschington/>} />
          <Route path="/article/:article_ref" element={<Article/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
