
import { Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import './ComingSoon.scss';

export default function ComingSoon(props) {
  const [state, setState] = useState({mobileView: false});

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const displayMobile = () => {
    return (
      <Container className="coming-soon coming-soon-mobile">
        Coming Soon...<div>{props?.name ? '\n ' + props.name : ''}</div>
      </Container>
    );
  };

  const displayDesktop = () => {
    return (
      <Container className="coming-soon">
        Coming Soon...<div>{props?.name ? '\n ' + props.name : ''}</div>
      </Container>
    );
  };

  return (
    <Container>
      {mobileView ? displayMobile() : displayDesktop()}
    </Container>
  );
}