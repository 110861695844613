
import { AppBar, Toolbar, makeStyles, Button, IconButton, Link, MenuItem, Drawer } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import variables from '../variables.scss';
import logoImage from '../logo192.png';
import './Header.scss';

const headersData = [
  {
    label: "iRace",
    href: "/iracing"
  },
  {
    label: "We Gamble",
    href: "/gambling"
  },
  {
    label: "Buschington @ The Glen",
    href: "/buschington"
  },
];

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: variables.primaryFont,
    color: variables.primaryGreen
  },
  toolbar: {
    paddingLeft: "0px" // replaced by logo.menuPadding;
  },
  logoButton: {
    padding: "0px" // replaced by logo.menuPadding;
  },
  logo: {
    height: variables.logoHeight,
    paddingLeft: variables.menuPadding,
    paddingRight: variables.menuPadding
  },
  menuButton: {
    height: variables.logoHeight,
    fontWeight: variables.menuFontWeight,
    paddingLeft: variables.menuPadding,
    paddingRight: variables.menuPadding,
    "&:hover, &:focus": {
      color: variables.primaryPurple
    }
  },
  drawerPane: {
    background: variables.primaryFont,
    fontWeight: variables.menuFontWeight,
    color: variables.primaryGreen
  }
}));

export default function Header() {
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false
  });

  const { mobileView, drawerOpen } = state;

  const { header, toolbar, drawerPane, logo, logoButton, menuButton } = useStyles();

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {getHomeButton()}
        {getMenuButtons()}
      </Toolbar>
    );
  };
  
  const handleDrawerClose = () => {
    setState((prevState) => ({...prevState, drawerOpen: false}));
  }

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            to: href,
            color: "inherit",
            style: {textDecoration: "none"},
            key: label,
            component: RouterLink,
            onClick: handleDrawerClose
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const displayMobile = () => {
    const handleDrawerOpen = () => {
      setState((prevState) => ({...prevState, drawerOpen: true}));
    }

    return (
      <Toolbar className={toolbar}>
        {getHomeButton()}
        <IconButton
          {...{
            onClick: handleDrawerOpen,
          }}
        >
        <MenuIcon/>
        </IconButton>
        <Drawer classes={{paper: drawerPane}}
          {...{
            anchor: "top",
            open: drawerOpen,
            onClose: handleDrawerClose
          }}
        >
          <div>{getDrawerChoices()}</div>
        </Drawer>
      </Toolbar>
    );
  };

  const iRaceLogo = (<img className={logo} src={logoImage} alt="iRaceWeGambleLogo"></img>);

  const getHomeButton = () => {
    return (
      <Button
        {...{
          to: "",
          component: RouterLink,
          className: logoButton
        }}
      >
        {iRaceLogo}
      </Button>
    )
  };

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
            className: menuButton
          }}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <AppBar position="static" className={header}>
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
}