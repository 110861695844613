import Daytona500Image from './images/2022daytona500.jpg';
import Fontana22Image from './images/2022.Fontana.400.jpg';
import Vegas22Image from './images/2022Vegas.jpg';
import Phoenix22Image from './images/2022Phoenix.jpg';
import Atlanta22Image from './images/2022ATL.jpg';

const ArticleList =
    [
        {
            ref: '2022.Atlanta.1',
            image: Atlanta22Image,
            title: 'Next Gen Car, or Driver?',
            date: '3/20/22 1:45pm EDT',
            byLine: 'Joshua Symer, edited by: Alex Crumb',
            firstLine: "The Folds of Honor Quick Trip 500 is this weekend at Atlanta Motor Speedway. Now that practice is over, let's look at the field and how we want to set our DFS lineups.",
            firstParagraph: "This weekend the inclement weather canceled Friday practice and NASCAR made the decision to push it back to Saturday, canceling qualifying instead. The lineup for this week falls back to the equation used to determine the starting lineup last year, which factors in last weeks finishes and fastest laps at Phoenix, as well as points position. Atlanta was repaved and reconfigured in the off-season, making it a brand-new track for the drivers. What we saw during practice looked oddly like super speedways (Daytona and Talladega). As we mentioned on the iRace We Gamble Podcast, it may not be a bad idea to set your lineups like you would for Daytona rather than Atlanta.",
            contentList: [
                {
                    type: "listWithTitle",
                    sections: [
                        {
                            title: 'Talented Drivers Deep in the Pack',
                            list: [
                                {
                                    title: "Kyle Larson, #5",
                                    content: "Larson is overpriced again this weekend. That's the sentence I'd like to start this piece with. However, because of engine issues last weekend, he's starting 21st. The place differential makes him a tempting target regardless of the high price tag."
                                },
                                {
                                    title: "Brad Keselowski, #6",
                                    content: "Keselowski generally runs well at the super speedways and performed well at Daytona. He's starting 24th at a good price point ($7500), making him a strong play this weekend."
                                },
                                {
                                    title: "Denny Hamlin, #11",
                                    content: "Hamlin has similar success at super speedways to Keselowski. He starts 15th, but Joe Gibbs Racing has outperformed RFK so far this year. If you don't need to save money in your lineup, Hamlin is the choice."
                                },
                                {
                                    title: "Martin Truex Jr., #19",
                                    content: "Truex won the spring race here last year. He didn't look great in practice and qualifying, but it sounded like Joe Gibbs Racing understood what the car needed afterwards. He's expensive this week at $13,500, but his starting position of p20 makes him my preference for a dominator over Kyle Larson ($14,500)."
                                }
                            ]
                        },
                        {
                            title: 'The Under 30 Club',
                            list: [
                                {
                                    title: "Chase Briscoe, #14",
                                    content: "Briscoe finished 3rd in Daytona and won last weekend. He starts on the pole this weekend which I think will scare people off him in tournaments. Everything we've seen so far indicates it may be tough to pass, making Briscoe a strong DFS play in my opinion."
                                },
                                {
                                    title: "Daniel Suarez, #99",
                                    content: "These young Trackhouse drivers continue to perform. Suarez starts in 13th ($4,500) and teammate Chastain starts in 7th ($7,000). They're both solid options at their low and mid-tier price points."
                                },
                                {
                                    title: "Austin Cindric, #2",
                                    content: "Cindric won at Daytona. He starts 16th. He's in good equipment with Penske and it is hard to imagine liking a driver more this weekend."
                                }
                            ]
                        },
                    ]
                },
                {
                    type: "closingParagraph",
                    paragraph: "That's a wrap for the Folds of Honor Quik Trip 500 - for more insight on my picks, as well as Alex Crumb's, check out the iRace We Gamble Podcast and @iRaceWeGamble on Twitter!"
                },
                {
                    type: "dfsLineup",
                    title: "Josh's 2022 Folds of Honor Quik Trip 500 Tournament Lineup",
                    disclaimerOverride: null, //default: (Please note this may differ from the lineup given on the iRace, We Gamble Podcast)
                    driverList: [
                        {
                            driver: 'Denny Hamlin',
                            car: '11',
                            manufacturer: 'Toyota',
                            price: '12000',
                            startingSpot: '15'
                        },
                        {
                            driver: 'Austin Cindric',
                            car: '2',
                            manufacturer: 'Ford',
                            price: '9000',
                            startingSpot: '16'
                        },
                        {
                            driver: 'Bubba Wallace',
                            car: '23',
                            manufacturer: 'Toyota',
                            price: '8500',
                            startingSpot: '19'
                        },
                        {
                            driver: 'Chase Briscoe',
                            car: '14',
                            manufacturer: 'Ford',
                            price: '8300',
                            startingSpot: '1'
                        },
                        {
                            driver: 'Brad Keselowski',
                            car: '6',
                            manufacturer: 'Ford',
                            price: '7500',
                            startingSpot: '24'
                        }
                    ]
                }
            ]
        },
        {
            ref: '2022.Phoenix.1',
            image: Phoenix22Image,
            title: 'Ruoff Mortgage 500 - The Usual Suspects',
            date: '3/12/22 9:30pm EDT',
            byLine: 'Joshua Symer',
            firstLine: "The Ruoff Mortgage 500 at Phoenix Raceway is this weekend. There was almost nothing shocking or noteworthy during practice and qualifying, so we're going to keep things short and sweet this weekend.",
            firstParagraph: "The top 10 includes five Fords, three Chevys, and two Toyotas. It's apparent that teams are getting their cars tuned up well in the early part of the season. NASCAR may just be getting the parody they were hoping for this season. This parody is the reason why I think we're going to have our fourth unique winner in four races this season.",
            contentList: [
                {
                    type: "listWithTitle",
                    sections: [
                        {
                            title: 'Big names to consider',
                            list: [
                                {
                                    title: "Kyle Larson, #5",
                                    content: "Larson is overpriced this weekend. Yes, he won the Championship at Phoenix last year, but the fact that he is priced $1,000 over the next highest driver (Martin Truex Jr.) makes him a tough play this week regardless of his p7 qualifying position. He has been great early in the season; however, it feels like you can build competitive lineups this weekend without him."
                                },
                                {
                                    title: "Kevin Harvick, #4",
                                    content: "In Kevin Harvick's last 10 races at Phoenix, he has amassed an impressive ten top 10 finishes. Considering his starting position of 16th at a price point of only $10,000 he is a veritable lock this weekend."
                                },
                                {
                                    title: "Denny Hamlin, #11",
                                    content: "Denny Hamlin is my lock of the week. My confidence in his performance this weekend is dangerously high. After his snafu at Las Vegas, Hamlin tweeted “The fastest car on the track today. The worst driver on the track today. Great improvements by everyone Joe Gibbs Racing and Toyota Racing. Get me to Phx.” It's hard to disagree with the sentiment about his car, and even though he starts p2, I'm going to have a lot of shares of Hamlin at only $11,500."
                                },
                                {
                                    title: "Martin Truex Jr., #19",
                                    content: "Truex won the spring race here last year. He didn't look great in practice and qualifying, but it sounded like Joe Gibbs Racing understood what the car needed afterwards. He's expensive this week at $13,500, but his starting position of p20 makes him my preference for a dominator over Kyle Larson ($14,500)."
                                }
                            ]
                        },
                        {
                            title: 'Mid-priced Lineup Fillers ($6,000-$9,000)',
                            list: [
                                {
                                    title: "Aric Almirola, #10",
                                    content: "The best thing I can tell you about Almirola is that he's 3 for 3 in top 10 finishes this year. He has speed again in practice and qualifying, securing a 5th place starting position. FanDuel understands how well he's run early this season, pushing his price up to $8,500 this weekend. If you like the Stewart-Haas success on this track and don't want to spend on Harvick or Almirola, Cole Custer (p25, $5,000) is also a solid choice."
                                },
                                {
                                    title: "Ross Chastain, #1",
                                    content: "Chastain was great in Las Vegas and showed speed in practice at Phoenix. The fact that he was only able to secure a 27th place starting position makes him a decent play this weekend at $7,500. He has struggled here in the past, finishing on the lead lap only three times in seven races. Now on Trackhouse Racing, Chastain is a driver to add based on recent history rather than track performance."
                                },
                                {
                                    title: "Austin Cindric, #2",
                                    content: "Cindric's #2 Penske Ford has looked fast all season, including early this week in practice and qualifying. He starts p8, which is a position in which I'd usually only recommend experienced Cup Series drivers. He's only priced at $7,800 making him a potential differentiation play this weekend at Phoenix."
                                },
                                {
                                    title: "Ricky Stenhouse Jr., #47",
                                    content: "I have this driver every week, the one who misses qualifying and starts at the back. Last week it was Kyle Busch who drove to the most DFS points in the field. Stenhouse had engine issues which forced him to miss qualifying. He's only $7,300 this weekend and starts last (p36). He won't give you a Kyle Busch performance, but if he gets to his average finish inside the top 20, he's a good option for lineups this weekend."
                                }
                            ]
                        },
                    ]
                },
                {
                    type: "closingParagraph",
                    paragraph: "That's a wrap for the Ruoff Mortgage 500 - for more insight on my picks, as well as Alex Crumb's, check out the iRace We Gamble Podcast and @iRaceWeGamble on Twitter!"
                },
                {
                    type: "dfsLineup",
                    title: "Josh's 2022 Ruoff Mortgage 500 Tournament Lineup",
                    disclaimerOverride: null, //default: (Please note this may differ from the lineup given on the iRace, We Gamble Podcast)
                    driverList: [
                        {
                            driver: 'Chase Elliott',
                            car: '9',
                            manufacturer: 'Chevy',
                            price: '12500',
                            startingSpot: '19'
                        },
                        {
                            driver: 'Denny Hamlin',
                            car: '11',
                            manufacturer: 'Toyota',
                            price: '11500',
                            startingSpot: '2'
                        },
                        {
                            driver: 'Kevin Harvick',
                            car: '4',
                            manufacturer: 'Ford',
                            price: '10000',
                            startingSpot: '16'
                        },
                        {
                            driver: 'Austin Cindric',
                            car: '2',
                            manufacturer: 'Ford',
                            price: '7800',
                            startingSpot: '8'
                        },
                        {
                            driver: 'Ricky Stenhouse Jr.',
                            car: '47',
                            manufacturer: 'Chevy',
                            price: '7300',
                            startingSpot: '36'
                        }
                    ]
                }
            ]
        },
        {
            ref: '2022.Vegas.1',
            image: Vegas22Image,
            title: "Penzoil 400: Fade the Camry's?",
            date: '3/5/22 7:24pm EST',
            byLine: 'Joshua Symer',
            firstLine: "The Penzoil 400 is this weekend at Las Vegas Motor Speedway. Now that practice and qualifying are over, let's look at the field and how we want to set our DFS lineups.",
            firstParagraph: "Las Vegas gives us our first look at the Next Gen car on a familiar track. That's not to say Daytona isn't familiar, we just all know anything can happen on those super speedways. Practice showed us a lot of speed out of the Chevy's and Ford's. Apart from Christopher Bell (2nd fastest practice), we must drop down to 11th to find the first Camry. It is the main reason why this week, I'm recommending going heavy on Ford and Chevy. There are some talented Camry drivers, particularly from Joe Gibbs Racings so if you want to add one of them to your lineup, I'm fine with that.",
            contentList: [
                {
                    type: "listWithTitle",
                    sections: [
                        {
                            title: 'Big names to consider',
                            list: [
                                {
                                    title: "Kyle Larson, #5",
                                    content: "Larson looks to be picking up where he left off last year. Despite his high price tag ($14,000), I still like him in lineups this weekend."
                                },
                                {
                                    title: "Ryan Blaney, #12",
                                    content: "Blaney looked strong during practice and qualified with some room to improve during the race. If you're looking for a slightly cheaper option than Larson, Blaney is a great choice."
                                },
                                {
                                    title: "Joey Logano, #22",
                                    content: "Logano's story is basically the same as Blaney's. He starts five positions better than Blaney for $500 more. Blaney is a stronger DFS option, but you can't go wrong with Logano."
                                },
                                {
                                    title: "Kyle Busch, #18",
                                    content: "KB wrecked his car during practice, so he will start last this weekend. He's too good in every important category - driver, team, and track history - to ignore this weekend. He immediately becomes my lock for the weekend."
                                }
                            ]
                        },
                        {
                            title: 'Performing Early in the Season',
                            list: [
                                {
                                    title: "Austin Dillon, #3",
                                    content: "Dillon finished 2nd last week and looked strong at Daytona before being involved in a late wreck. Priced at only $7,700 this week in Las Vegas, he's a great mid-tier choice for lineups."
                                },
                                {
                                    title: "Erik Jones, #43",
                                    content: "Last week I recommended fading Erik Jones because of his top 3 starting position. All he did: finished 3rd at his low price point, which made him an excellent play. If you're looking to save some budget, I don't think you can go wrong adding Jones to your lineup. Time will tell if he can maintain his hot start, or if the season will catch up to him as it did with Chris Buescher last year."
                                },
                                {
                                    title: "Tyler Reddick, #8",
                                    content: "Reddick has been fast, very fast. In both races this season, he has looked like one of the best cars on the track. If he doesn't catch more bad luck this weekend, look for him to finish well in Las Vegas this weekend."
                                },
                                {
                                    title: "Aric Almirola, #10",
                                    content: "Almirola is the only driver this year with top 10 finishes in the first two races. He seems to be doing his best Kevin Harvick 'The Closer' impression, finishing strong at Daytona and The Auto Club."
                                }
                            ]
                        },
                    ]
                },
                {
                    type: "closingParagraph",
                    paragraph: "That's a wrap for the Penzoil 400 - for more insight on my picks, as well as Alex Crumb's, check out @iRaceWeGamble on Twitter!"
                },
                {
                    type: "dfsLineup",
                    title: "Josh's 2022 Penzoil 400 Tournament Lineup",
                    disclaimerOverride: null, //default: (Please note this may differ from the lineup given on the iRace, We Gamble Podcast)
                    driverList: [
                        {
                            driver: 'Kyle Larson',
                            car: '5',
                            manufacturer: 'Chevy',
                            price: '14000',
                            startingSpot: '2'
                        },
                        {
                            driver: 'Kyle Busch',
                            car: '18',
                            manufacturer: 'Toyota',
                            price: '12000',
                            startingSpot: '37'
                        },
                        {
                            driver: 'Tyler Reddick',
                            car: '8',
                            manufacturer: 'Chevy',
                            price: '10000',
                            startingSpot: '7'
                        },
                        {
                            driver: 'Austin Dillon',
                            car: '3',
                            manufacturer: 'Chevy',
                            price: '7700',
                            startingSpot: '10'
                        },
                        {
                            driver: 'Aric Almirola',
                            car: '10',
                            manufacturer: 'Ford',
                            price: '6300',
                            startingSpot: '20'
                        }
                    ]
                }
            ]
        },
        {
            ref: '2022.Fontana.400',
            image: Fontana22Image,
            title: 'WISE Power 400: Spins Galore',
            date: '2/27/22 2:02pm EST',
            byLine: 'Joshua Symer',
            firstLine: 'One thing is for certain, going onto the apron in turn 4 is a high risk, high reward venture. During qualifying alone, we saw Almirola, Logano, Byron and Elliott all spin as they got loose coming out of the corner. Considering all teams have 12 sets of tires, I anticipate that drivers will continue to be aggressive through that section of the track.',
            firstParagraph: '',
            contentList: [
                {
                    type: "paragraphWithTitle",
                    sections: [
                        {
                            title: 'A Mixed Bag in the Top 5',
                            content: "Ordinarily, I tend to avoid drivers starting near the front unless I think they will dominate laps led. I don't think any one driver will do that this weekend at the Auto Club Speedway. Let's look at the top five starters: Austin Cindric, Erik Jones, Kyle Busch, Denny Hamlin, Daniel Hemric. If you listened to the podcast earlier this week, you know I went into the weekend liking Jones and Cindric in my lineups. That's not the case anymore. They don't have cars that dominate a race (although, Cindric might with Team Penske behind him), so considering their starting positions they become easy avoids this week. I was never considering Hemric, and with a starting position of 5, that hasn't changed. Now we get to Kyle Busch and Denny Hamlin. Two talented and proven drivers who had a good start to their weekend. I have no problem using one of them this weekend in lineups and lean toward Kyle Busch based on his strong history at this track."
                        },
                        {
                            title: 'Big Names Starting in the Back',
                            content: "You have to love the upside from place differential, so it's no surprise that I'm heavily recommending some big names starting deep in the field. Kurt Busch (p36) starts last and has a strong history here, which makes him my lock of the week. Kevin Harvick (p32) wrecked his #4 Ford Mustang in practice and was thought to be switching to the #41 backup car, but the team was able to repair the primary car for Sunday. For all his troubles, he will be starting 32nd, which makes him a strong option to pick up places. One other potentially strong car that didn't make it to qualiftying is the #1 Chevy of Ross Chastain. Chastain starts p33 and has plenty of ability to make a run through the field."
                        },
                        {
                            title: 'The Other Guys',
                            content: "There is a lot of talent starting in positions 6-20. Keselowski, Blaney, Larson, Truex, Logano, Elliott and many more strong drivers that can round out your lineup. Given the FanDuel pricing, the slate lacks value picks so look at this range of starting positions to build your lineup. My favorite value play this weekend is the #14 of Chase Briscoe, who is priced at $5,500 and starts p24. On the FanDuel Sportsbook, I have a one unit bet on Briscoe finishing top 10."
                        }
                    ]
                },
                {
                    type: "closingParagraph",
                    paragraph: "That's a wrap for the WISE Power 400 - for more insight on my picks, as well as Alex Crumb's, check out this week's episode of the iRace We Gamble Podcast and follow us over on twitter @iRaceWeGamble."
                },
                {
                    type: "dfsLineup",
                    title: "Josh's 2022 WISE Power 400 Tournament Lineup",
                    disclaimerOverride: null, //default: (Please note this may differ from the lineup given on the iRace, We Gamble Podcast)
                    driverList: [
                        {
                            driver: 'Kyle Busch',
                            car: '18',
                            manufacturer: 'Toyota',
                            price: '12000',
                            startingSpot: '3'
                        },
                        {
                            driver: 'Ryan Blaney',
                            car: '12',
                            manufacturer: 'Ford',
                            price: '11500',
                            startingSpot: '6'
                        },
                        {
                            driver: 'Kevin Harvick',
                            car: '4',
                            manufacturer: 'Ford',
                            price: '9700',
                            startingSpot: '32'
                        },
                        {
                            driver: 'Kurt Busch',
                            car: '45',
                            manufacturer: 'Toyota',
                            price: '9200',
                            startingSpot: '36'
                        },
                        {
                            driver: 'Chase Briscoe',
                            car: '14',
                            manufacturer: 'Ford',
                            price: '5500',
                            startingSpot: '24'
                        }
                    ]
                }
            ]
        },
        {
            ref: '2022.Daytona.500',
            image: Daytona500Image,
            title: 'Daytona 500 Preview: 2022 The Year of the Mustang?',
            date: '2/20/22 10am EST',
            byLine: 'Joshua Symer',
            firstLine: 'The Daytona 500 is this Sunday, February 20, 2022, and with it the new NASCAR season is officially underway. In this preview, we will look at the upcoming slate of drivers for the race and highlight a few of our favorites. But first I feel that it would be prudent to take a quick look at the 2022 rookie drivers.',
            firstParagraph: '',
            contentList: [
                {
                    type: "paragraphWithTitle",
                    sections: [
                        {
                            title: 'Harrison Burton, #21',
                            content: "Harrison Burton joins the Wood Brother's Racing team in the #21 Ford Mustang, replacing Matt DiBenedetto. Burton drove for Joe Gibbs Racing in the Xfinity Series in 2019 and 2020, ultimately making the playoffs in 2020 behind two regular season wins. Unfortunately, he was a first round exit. However, the young driver (21 years) joins a decent team and will look to establish himself as a staple in the Cup Series this year."
                        },
                        {
                            title: 'Todd Gilliland, #38',
                            content: "The son of former NASCAR driver and team owner David Gilliland, Todd (21 years) joins Front Row Motorsports in the #38 Ford Mustang. Todd, who has been racing for almost his entire life, has spent the last 4 years as a full-time Truck Series driver. Over those four years, Todd won twice. In 2021, he drove to ten top-ten and four top-five finishes to qualify for the playoffs."
                        },
                        {
                            title: 'Austin Cindric, #2',
                            content: "While Austin Cindric (23 years) is officially a rookie this year, those who have followed NASCAR probably already know the name. Austin was a part-time driver for Team Penske in 2021. Originally, Cindric was slated to move to Wood Brother's Racing full time 2022. Instead, he stays with Penske in the #2 Ford Mustang following Brad Keselowski's move to Rousch Fenway Racing (now Rousch Fenway Keselowski Racing). Cindric performed well in 2021, specifically on road courses. He led laps at Road America and Circuit of the Americas, as well as finishing ninth at the Indy Roval. He's a driver to watch, especially on road courses this year, and is my personal pick as the early favorite for Rookie of the Year."
                        },
                        {
                            title: '2022: The Year of the Mustang?',
                            content: "It is as early as early gets in the NASCAR season, but we are already seeing dominance and speed from the Ford Mustang's. In the final Daytona practice, the 5 fastest cars were all Ford's, being led by rookie driver Harrison Burton. The Daytona field is always wide open, therefore, for DFS purposes my recommendations can be summed up as thus:"
                        }
                    ]
                },
                {
                    type: "numberedList",
                    numberedList: [
                        "Lean to drivers deeper in the field. For this purpose, Denny Hamlin (p30) and Joey Logano (p20) immediately jump off the page. Anything can happen at Daytona and having drivers in your lineup who start further back limits a wreck destroying your lineup. It helps that Hamlin and Logano are both very good at Daytona, ranking first and third respectively based on driveraverages.com.",
                        "Go Ford heavy. They clearly have found something early in the Next Gen car, and there are a lot of them. While we're unsure of exactly how pack racing will be affected by the new car, it is a safe bet to ride with the Ford Mustang's. I recommend having at least 3 of them in your tournament lineups.",
                        "Don't be afraid to round out your lineup with a $2,000-$4,500 priced driver. Gilliland ($3,000, p32) and McLeod ($2,000, p37) are fine choices as a low budget Ford drivers to round out your lineup. If they finish the race, they won't hurt your lineup and allow you to go top-heavy with your other four drivers.",
                        "Fade Michael McDowell. The reigning Daytona 500 champion is priced at only $4,500, drives a Ford and showed good speed in practice and qualifying. Unfortunately for DFS purposes, his p6 starting position drastically increases his risk should he be involved in an incident.",
                        "Fade Kyle Larson. He starts on the pole, is priced at only $12,000 (potentially the lowest we will see all year), and is the 14th rated driver on Driver Averages. All of this makes him an easy avoid this weekend."
                    ]
                },
                {
                    type: "closingParagraph",
                    paragraph: "That's all for the Daytona 500. I sincerely hopes this helps, and I look forward to another year in the green in DFS tournament play for the 2022 NASCAR season."
                },
                {
                    type: "dfsLineup",
                    title: "Josh's 2022 Daytona 500 Tournament Lineup",
                    disclaimerOverride: null, //default: (Please note this may differ from the lineup given on the iRace, We Gamble Podcast)
                    driverList: [
                        {
                            driver: 'Denny Hamlin',
                            car: '11',
                            manufacturer: 'Toyota',
                            price: '14000',
                            startingSpot: '30'
                        },
                        {
                            driver: 'Joey Logano',
                            car: '22',
                            manufacturer: 'Ford',
                            price: '13000',
                            startingSpot: '20'
                        },
                        {
                            driver: 'Aric Almirola',
                            car: '10',
                            manufacturer: 'Ford',
                            price: '8500',
                            startingSpot: '38'
                        },
                        {
                            driver: 'Austin Dillon',
                            car: '3',
                            manufacturer: 'Chevy',
                            price: '8200',
                            startingSpot: '36'
                        },
                        {
                            driver: 'Chase Briscoe',
                            car: '14',
                            manufacturer: 'Ford',
                            price: '6000',
                            startingSpot: '9'
                        }
                    ]
                }
            ]
        },
    ];

export default ArticleList;