import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@material-ui/core";
import { getArticle } from "../services/ArticleService";
import NumberFormat from 'react-number-format';

export default function Article() {
  const [articleData, setArticleData] = useState([]);
  let { article_ref } = useParams();

  useEffect(() => {
    getArticle(article_ref).then((res) => setArticleData(res));
  }, [article_ref]);

  return <Container style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "stretch", gap: "11px", marginTop: "11px", marginBottom: "11px" }}>
    
    <div {...{style: {color: "#D5D6D2", maxWidth: "80%", margin: "8%", marginTop: "4%", padding: "2%", background: "rgba(0, 0, 0, 0.3)", borderRadius: "0.5em"}}}>
      <h1 {...{style: {fontSize: "64px", marginTop: "0"}}}>{articleData.title}</h1>
      <p>
        <div>
          <div {...{style: {paddingBottom: "11px", fontSize: "10pt"}}}>{articleData.date}</div>
          <div>Written by {articleData.byLine}</div>
          <div {...{style: {paddingTop: "11px", fontSize: "10pt"}}}>Note: All DFS pricing and recommendations are based on FanDuel tournament scoring</div>
          <p {...{style: {paddingTop: "22px"}}}>
            {articleData.girstLine}
          </p>
          {articleData.firstParagraph && articleData.firstParagraph.length > 0 &&
            <p>
              {articleData.firstParagraph}
            </p>
          }
        </div>
      </p>
      {articleData.contentList && articleData.contentList.map((value, index) => {
        if (value.type === 'paragraphWithTitle' && value.sections) {
          return value.sections.map((sectValue, sectIndex) => {
            return (<div key={'listID: ' + index + " sectID: " + sectIndex}>
              <p {...{style: {paddingTop: "22px"}}}><h1>{sectValue.title}</h1></p>
              <p><span>{sectValue.content}</span></p>
            </div>);
          });
        }
        if (value.type === 'listWithTitle' && value.sections) {
          return value.sections.map((sectValue, sectIndex) => {
            return (<div key={'listID: ' + index + " sectID: " + sectIndex}>
              <p {...{style: {paddingTop: "22px"}}}><h1>{sectValue.title}</h1></p>
              {sectValue.list.map((listItem) => {
                return (<p><span  {...{style: {fontWeight: "bold"}}}>{listItem.title}</span><span> - {listItem.content}</span></p>);  
              })}
            </div>);
          });
        }
        if (value.type === 'numberedList' && value.numberedList) {
          return (
            <ol>{value.numberedList.map((listItem, listIndex) => {
              return (<li key={listIndex}><p>{listItem}</p></li>);
            })}</ol>
          );
        }
        if (value.type === 'closingParagraph' && value.paragraph) {
          return (<p  {...{style: {paddingTop: "22px"}}}><span>{value.paragraph}</span></p>);
        }
        if (value.type === 'dfsLineup' && value.title && value.driverList && value.driverList.length > 0) {
          return (
            <div>
              <div {...{style: {fontWeight: "bold", paddingTop: "22px"}}}>{value.title}</div>
              <div>(Please note this may differ from the lineup given on the iRace, We Gamble Podcast)</div>
              <p>{value.driverList.map((driverItem, driverIndex) => {
                return (<div>#{driverItem.car} {driverItem.manufacturer}, {driverItem.driver} - <NumberFormat value={driverItem.price} displayType={'text'} thousandSeparator={true} prefix={'$'}/>, p{driverItem.startingSpot}</div>);
              })}</p>
            </div>
          );
        }

        return <div>Issue Displaying Section</div>;
      })}
  </div>
</Container>;
}
