
import { useState, useEffect } from "react";
import { Card, CardMedia, CardContent, CardActions, Button, Typography } from '@mui/material';
import { Container } from "@material-ui/core";
import { getNthMostRecentArticle } from "../services/ArticleService";
import variables from '../variables.scss';
import iRacingImage from './images/iRace-truck-v1.PNG';
import oddsImage from './images/bets.png';

export default function Gamble() {
  const [recentArticleData, setRecentArticleData] = useState([]);

  useEffect(() => {
    getNthMostRecentArticle(0).then((res) => setRecentArticleData(res));
  }, );
  
  const handlePathClick = value => () => {
    window.location.href=value;
  };

  return (
    <Container style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "stretch", gap: "11px", marginTop: "11px", marginBottom: "11px" }}>
      <Card sx={{ width: 500, display: "flex", flexDirection: "column", justifyContent: "flex-start", backgroundColor: variables.primaryFont }}>
        <CardMedia
          component="img"
          height="300"
          image={recentArticleData.image}
          alt="Article Image"
        />  
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{color: variables.primaryPurple}}> 
            {recentArticleData.title}
          </Typography>
          <Typography variant="body1" color={variables.primaryPurple} sx={{ paddingBottom: "11px" }} >
            {recentArticleData.date}
          </Typography>
          <Typography variant="body2" color={variables.primaryPurple}>
            {recentArticleData.firstLine}
            {recentArticleData.firstParagraph && recentArticleData.firstParagraph !== '' &&
              <div style={{marginTop: "11px"}}>{recentArticleData.firstParagraph}</div>
            }
          </Typography>
        </CardContent>
        <CardActions sx={{ marginTop: "auto", justifyContent: "space-between" }}>
          <Button sx={{color: variables.primaryGreen}} size="large" onClick={handlePathClick('/article/' + recentArticleData.ref)}>Read Full Article</Button>
          <Button sx={{color: variables.primaryGreen}} size="large"  onClick={handlePathClick('/gambling/')}>More Articles</Button>
        </CardActions>
      </Card>

      <Card sx={{ width: 350, display: "flex", flexDirection: "column", justifyContent: "flex-start", backgroundColor: variables.primaryFont }}>
        <CardMedia
          component="img"
          height="200"
          image={oddsImage}
          alt="Odds Image"
        />  
        <CardContent sx={{color: variables.primaryPurple}}>
          <Typography variant="body2" color={variables.primaryPurple}>
            <div style={{fontWeight: "bold"}}>EchoPark Automotive GP Odds (3/23/22)</div>
            <div style={{paddingLeft: "11px"}}>
              <div>Chase Elliott (+270)</div>
              <div>Kyle Larson (+350)</div>
              <div>Kyle Busch (+1000)</div>
              <div>Martin Truex Jr. (+1000)</div>
              <div>Austin Cindric (+1400)</div>
              <div>Denny Hamlin (+1600)</div>
            </div>
            <div style={{fontWeight: "bold", paddingTop: "11px"}}>F1 Saudi Arabian GP Odds (3/23/22)</div>
            <div style={{paddingLeft: "11px"}}>
              <div>Charles Leclerc (+130)</div>
              <div>Max Verstappen (+150)</div>
              <div>Carlos Sainz (+650)</div>
              <div>Lewis Hamilton (+1000)</div>
              <div>Sergio Perez (+1800)</div>
              <div>George Russell (+2800)</div>
            </div>
            <div style={{fontWeight: "bold", paddingTop: "11px"}}>FanDuel ideal lineup for Atlanta</div>
            <div style={{paddingLeft: "11px"}}>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#24, William Byron</div>
                <div style={{minWidth: "75px"}}>92.1pts</div>
                <div style={{flexGrow: "1"}}>$10.5k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#7, Corey Lajoie</div>
                <div style={{minWidth: "75px"}}>82.5pts</div>
                <div style={{flexGrow: "1"}}>$3k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#1, Ross Chastain</div>
                <div style={{minWidth: "75px"}}>79.2pts</div>
                <div style={{flexGrow: "1"}}>$7k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#99, Daniel Suarez</div>
                <div style={{minWidth: "75px"}}>75.3pts</div>
                <div style={{flexGrow: "1"}}>$4.5k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px"}}>
                <div style={{minWidth: "150px"}}>#19, Martin Truex Jr.</div>
                <div style={{minWidth: "75px"}}>72pts</div>
                <div style={{flexGrow: "1"}}>$10k</div>
              </div>
              <div style={{display: "flex", flexFlow: "row nowrap", paddingRight: "22px", fontWeight: "bold"}}>
                <div style={{minWidth: "150px"}}>Totals</div>
                <div style={{minWidth: "75px"}}>404.1pts</div>
                <div style={{flexGrow: "1"}}>$35k</div>
              </div>
            </div>
          </Typography>
        </CardContent>
        <CardActions sx={{ marginTop: "auto" }}>
          <Button sx={{color: variables.primaryGreen}} size="large" href="https://sportsbook.fanduel.com/motorsport">More Motorsports Odds</Button>
        </CardActions>
      </Card>

      <Card sx={{ width: 350, display: "flex", flexDirection: "column", justifyContent: "flex-start", backgroundColor: variables.primaryFont }}>
        <CardMedia
          component="img"
          height="200"
          image={iRacingImage}
          alt="iRacing Image"
        />
        <CardContent>
          <Typography variant="body2" color={variables.primaryPurple}>
            <div style={{fontWeight: "bold"}} >BeefJerkyUnlimited Series</div>
            <div style={{paddingLeft: "11px"}}>
              <div>Daytona:<span style={{fontWeight: "bold"}} > DNS/DNF</span></div>
              <div>Las Vegas:<span style={{fontWeight: "bold"}} > 8th</span></div>
              <div>Auto Club:<span style={{fontWeight: "bold"}} > 7th</span></div>
              <div>Atlanta:<span style={{fontWeight: "bold"}} > 26th (-4 laps)</span></div>
              <div>Watkins Glen:<span style={{fontWeight: "bold"}} > 4th</span></div>
              <a href="https://www.simracerhub.com/scoring/season_standings.php?season_id=15780">Point standings</a>
            </div>
            <div style={{fontWeight: "bold", paddingTop: "11px"}}>Recent Races</div>
            <div style={{paddingLeft: "11px"}}>
              <div>C fix [3/8 split] @ Atlanta, p2 (4x, +92 iR)</div>
              <div>A fix [2/3 split] @ Atlanta, p10 (6x, +23 iR)</div>
              <div>A fix [1/1 split] @ COTA, p16 (14x, +7 Road iR)</div>
              <div>A fix [2/2 split] @ COTA, p4 (4x, +51 Road iR)</div>
            </div>
            <div style={{fontWeight: "bold", paddingTop: "11px"}}>Across iRacing</div>
            <div style={{paddingLeft: "11px"}}>
              <div><a href="https://www.enascar.com/news-media/2022/03/16/steven-wilson-takes-first-career-enascar-coca-cola-iracing-series-victory-in-atlanta/">Steven Wilson wins in virtual Atlanta</a></div>
              <div><a href="https://www.enascar.com/schedule/">eNASCAR Coke Series Schedule</a></div>
              <div><a href="https://www.iracing.com/">iRacing Homepage</a></div>
            </div>
          </Typography>
        </CardContent>
        <CardActions sx={{ marginTop: "auto" }}>
          <Button sx={{color: variables.primaryGreen}} size="large" onClick={handlePathClick('/iracing/')}>More iRacing</Button>
        </CardActions>
      </Card>
    </Container>
  );
}
